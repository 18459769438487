<template>
  <div 
    id="map"
  >
    <fullscreen
      ref="container"
      class="pa-0 fill-height d-flex flex-column align-stretch"
      @change="fullscreenChange"
    >
      <data-tabs 
        :tabs="metrics"
        :metric="metric"
        :auditor="auditor"
        ref="tabs"
        class=""
        @metric-change="onMetricChange"
      />
      <v-progress-linear
        :active="loading"
        indeterminate
        height="2"
        color="primary"
        class="loading faded"
      />
      <!-- <segment-filter
        :items="segments"
        :selected="segment"
        @select="onSegmentSelection"
        @save="onSegmentSave"
      /> -->
      <data-location-card
        :geofences="data.geofences"
        :pois="data.pois"
        :cities="cities"
        :city="city"
        :metrics="metrics"
        :metric="metric"
        :layer="layer"
        :layers="layerOptions"
        :report="report"
        :max-height="maxHeight"
        :max-width="320"
        fit-height
        :fullscreen="fullscreen"
        :roles="user.roles"
        map
        outlined
        ref="maps"
        class="data-geo flex-grow-1 fill-width"
        @metric-change="onMetricChange"
        @city-change="onCityChange"
        @layer-change="onLayerChange"
        @select-item="onItemSelected"
        @toggle-fullscreen="toggleFullscreen"
        @zoom-end="onZoomEnd"
      />
    </fullscreen>
  </div>
</template>

<style scoped>

  #map {
    padding: 0 !important;
  }

</style>

<script>
  import services from '@/services';
  import { geofences as getGeofences } from '@/api/dashboard';
  const moment = require('moment');

  import { getGoogleMapsAPI as gmapApi } from 'gmap-vue';

  // Clients
  const diageoPois = () => import('@/assets/data/diageo-pois.json');
  const descomplica57Pois = () => import('@/assets/data/descomplica-57-pois.json');
  const descomplica40Pois = () => import('@/assets/data/descomplica-40-pois.json');
  const descomplica16Pois = () => import('@/assets/data/descomplica-16-pois.json');
  const boticarioPois = () => import('@/assets/data/boticario-pois.json');
  const boticarioPois283 = () => import('@/assets/data/boticario-pois-283.json');
  const boticarioPois438 = () => import('@/assets/data/boticario-pois-438.json');
  const boticarioPois481 = () => import('@/assets/data/boticario-pois-481.json');
  const boticarioPois513 = () => import('@/assets/data/boticario-pois-513.json');
  const boticarioPois514 = () => import('@/assets/data/boticario-pois-514.json');
  const boticarioPois540 = () => import('@/assets/data/boticario-pois-540.json');
  const boticarioPois666 = () => import('@/assets/data/boticario-pois-666.json');
  // const oohPois = () => import('@/assets/data/ooh-pois.json');
  // const itauPersonalite = () => import('@/assets/data/itau_personalite-pois.json');

  export default {
    name: 'Map',

    props: {
      metric: {
        type: String,
        default: 'audience'
      },
      layer: {
        type: String,
        default: 'geofences'
      },
      layers: {
        type: [Array, Object],
        default: () => [],
      },
      dataKey: {
        type: String,
        default: null
      },
      period: {
        type: Array,
        default: () => null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      campaigns: {
        type: Object,
        default: () => null
      },
      cities: {
        type: Object,
        default: () => {}
      },
      city: {
        type: Object,
        default: () => null
      },
      segment: {
        type: Number,
        default: 0
      },
      segments: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      stored: {
        type: Object,
        default: () => {}
      },
      report: {
        type: Boolean,
        default: false,
      },
      auditor: {
        type: Boolean,
        default: false,
      },
      budget: {
        type: Number,
        default: 0
      },
      duration: {
        type: Array,
        default: () => null
      },
    },

    data: () => ({
      fullscreen: false,
      pipeline: {
        geofences: { 
          get: 'getGeofences',
          set: 'setGeofences',
          type: 6,
          key: null,
          loading: false,
          updated: false,
        },
        pois: { 
          get: 'getGeofences',
          set: 'setPois',
          type: 7,
          key: null,
          loading: false,
          updated: false,
        },
      },
      metrics: {
        audience: { 
          title: 'Impactos',
          unit: 'Impactos',
          total: {
            value: null,
            format: '0[.]0 a'
          },
          loading: false,
        },
        impressions: { 
          title: 'Impressões',
          unit: 'Impressões',
          total: {
            value: null,
            format: '0[.]0 a'
          },
          loading: false,
        },
        spent: { 
          title: 'Investimento',
          unit: 'R$',
          total: {
            value: null,
            format: '$ 0[.]0 a'
          },
          roles: [1,5,6,7],
          loading: false,
        },
        cpm: { 
          title: 'CPM',
          unit: 'CPM',
          total: {
            value: null,
            format: '$ 0[.]00 a'
          },
          inverted: true,
          roles: [1,5,6,7],
          loading: false,
        },
        airtime: { 
          title: 'Horas no ar',
          unit: 'Horas',
          total: {
            value: null,
            format: '0[.]0[,]0 a'
          },
          loading: false,
        },
      },
      data: {
        geofences: {
          toggle: false,
          data: {},
          type: 'geofences',
          zoom: [],
        },
        pois: {
          toggle: false,
          point: false,
          data: [],
          clients: {
            source: {},
            set: null
          },
          zoom: []
        },
      },
      source: {
        prototype: {
          geofences: [],
          pois: [],
        }
      },
      maxHeight: '80%',
    }),

    components: {
      // SegmentFilter: () => import('@/components/SegmentFilter'),
      DataTabs: () => import('@/components/DataTabs'),
      DataLocationCard: () => import('@/components/DataLocationCard'),
    },

    computed: {
      google: gmapApi,

      tabs () {
        const roles = this.user.roles;
        const tabs = _.pickBy(this.metrics, d => !_.has(d, 'roles') ||  _.size(_.intersection(d.roles, roles))>0);
        return tabs;
      },

      layerOptions () {
        const pipeline = this.pipeline;
        return _.map(this.layers, layer => {
          return {
            ...layer,
            loading: layer.value=='pois' ? pipeline.pois.loading : pipeline.geofences.loading,
            disabled: (layer.value!='cities'&&this.city==null)||(layer.value=='pois'&&!pipeline.pois.loading&&_.isEmpty(this.data.pois.data))
          }
        })
      },

      supported () {
        const cities = this.cities;
        return _.reduce(cities, (geofences, city) => {
          _.each(city.zones, zone => {
            _.each(zone.geofences, g => {
              geofences[g.id] = {
                id: g.id,
                title: g.title,
                url: g.url,
                zone: { id: zone.id, title: zone.title, url: zone.url },
                city: { id: city.id, title: city.title, url: city.url },
                state: _.clone(city.state),
                country: _.clone(city.country),
              }
            });
          })
          return geofences;
        }, {});
      },

      loading () {
        return _.some(this.pipeline, 'loading');
      },
      updated () {
        return _.every(this.pipeline, 'updated');
      },
    },

    watch: {
      google: {
        immediate: true,
        deep: true,
        handler (v) {
          if (v!==false&&!!v) this.clientsPois();
        }
      },
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          // auto select layer
          // this.autoSelectLayer();
        }
      },
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
        }
      },
      updated: {
        immediate: true,
        handler (b) {
          const data = _.clone(this.data);
          this.$emit('update', b, data);
        }
      }
    },

    methods: {
      ...services,
      getGeofences,

      setGeofences (key, source, store) {
        this.pipeline.geofences.key = key;
        this.source[key].geofences = source;

        if (_.isEmpty(source)) {
          this.toggleToast(
            true,
            'Não há dados para a ' + this.getDictionary('campaign') + ' no período selecionado.',
            5000,
            false,
          );
          return null;
        }else{
          source = Object.freeze(source);
        }

        // set data totals
        const data = _.reduce(source, (total, g) => {
          return _.mapValues(total, (metric, m) => {
            return metric==null ? g[m] : g[m] + metric;
          })
        }, {..._.mapValues(_.clone(this.metrics), d => null), hours: null});
        data.cpm = (data.spent/data.audience) * 1000;
        data.airtime = data.hours / 60 / 60;
        this.$delete(data, 'hours');

        _.each(data, (d, metric) => {
          this.metrics[metric].total.value = d;
        })

        source = _.reject(source, ['id', 0]);
        
        // set geofences data
        this.data.geofences.data = _.reduce(_.keyBy(source, 'id'), (processed, geofence) => {
          const { id, title, audience, impressions, spent, cpm, hours } = geofence;
          if (_.has(this.supported, id)) {
            const info = this.supported[id];
            processed[id] = {
              id,
              title,
              audience, 
              impressions, 
              spent, 
              cpm,
              airtime: hours / 60 / 60,
              ...info,
            }
          }
          return processed
        }, {});

        if (_.isNil(store)||store) this.storeData('geofences', source);

        this.loadNext();
      },

      setPois (key, source, store) {
        this.pipeline.pois.key = key;
        this.source[key].pois = source;

        // if (_.isEmpty(source)) {
        //   this.toggleToast(
        //     true,
        //     'Não há dados de Pontos de Interesse para a ' + this.getDictionary('campaign') + ' no período selecionado.',
        //     5000,
        //     false,
        //   );
        //   return null;
        // }else{
        //   source = Object.freeze(_.orderBy(_.reject(source, ['id', 0]), ['day'], ['asc']));
        // }
        source = Object.freeze(_.orderBy(_.reject(source, ['id', 0]), ['day'], ['asc']));
        
        // set map pois data
        this.data.pois.data = _.concat(_.map(source, g => {
          const { id, title, type, use, audience, impressions, spent, cpm, hours } = g;
          const { title: city, state, country } = this.cities[g.city_id];
          return {
            id,
            title,
            type,
            use,
            audience, 
            impressions, 
            spent, 
            cpm,
            airtime: hours / 60 / 60,
            url: _.has(g, 'url') ? g.url : null,
            city: {
              id: g.city_id,
              city,
            },
            state,
            country
          }
        }), this.getClientPois());

        if (_.isNil(store)||store) this.storeData('pois', source);

        this.loadNext();
      },

      storeData (dimension, source) {
        console.log('store', dimension);
        const key = this.dataKey.toString();
        this.$emit('store-data', {
          key, 
          dimension,
          source
        })
      },

      updateView () {
        this.loadNext();
      },

      clearData () {
        console.log('cleaning up data...')
        _.each(this.pipeline, p => {
          p.updated = false;
        });
        _.each(this.metrics, d => {
          d.total.value = null;
        });
        this.onLayerChange('cities');
        this.data.geofences.data = {};
        this.data.pois.data = [];
        this.data.pois.clients.set = null;
      },

      getStoredData (key, dimension) {
        return _.has(this.stored, dimension) && this.stored[dimension].key==key ? this.stored[dimension] : null;
      },

      loadNext () {
        const $ = this;
        const s = this.dataKey.toString();
        if (!_.has(this.source, s)) {
          this.source[s] = _.clone(this.source.prototype);
        }
        console.log('source', s);
        const source = this.source;
        _.each(this.pipeline, (p, k) => {
          const store = $.getStoredData(s, k);
          if (p.key!=s||!p.updated&&!p.loading) {
            if (!_.isNil(store)) {
              const updated = moment().diff(moment(store.timestamp), 'minutes')<15;
              console.log('stored', k, updated);
              p.updated = updated;
              p.loading = !updated;
              $[p.set](s, store.source, false);
              if (updated) return false;
            }
            p.loading = true;
            $[p.get](
              $.user.auth.token,
              $.dataKey.toString(),
              $.scope, 
              $.children, 
              $.period,
              p.type,
              (key, data) => {
                if (key==s) {
                  p.updated = true;
                  $[p.set](key, data);
                }
              },
              (error) => {
                const msg = 'Aguardando resposta da nuvem...';
                setTimeout(($) => {
                  this.handleError(error, msg, [
                    true,
                    msg,
                    7000,
                    false
                  ]);
                  setTimeout(($) => $.loadNext(), 5000, $);
                }, 5000, this);
              },
              () => {
                p.loading = false;
              }
            );
            p.updated = false;
            return p.updated;
          }else{
            p.updated = true;
            return p.updated;
          }
        });
      },

      toggleFullscreen () {
        this.$refs['container'].toggle();
      },

      fullscreenChange (b) {
        this.fullscreen = b;
      },

      onMetricChange (metric) {
        console.log('metric change: '+metric);
        this.$emit('metric-change', metric);
      },

      onCityChange (city) {
        this.$emit('city-change', city);
      },

      onLayerChange (layer) {
        this.$emit('layer-change', layer);
      },

      // autoSelectLayer () {
      //   this.$emit('layer-change', null);
      // },

      onZoomEnd (layer) {
        layer = layer in ['pois', 'geofences'] ? layer : 'geofences';
        this.data[layer].zoom = [];
      },

      onItemSelected (item) {
        const layer = _.indexOf(['pois', 'geofences'], this.layer)>=0 ? this.layer : 'geofences';
        console.log(layer, item);
        this.data[layer].zoom = [item];
      },

      onSegmentSelection (id) {
        this.segment = id;
      },

      onSegmentSave (segment) {
        _.set(this.segments, segment.id, segment);
      },

      onSegmentDelete (id) {
        // _.set(this.segments, segment.id, segment);
      },

      getAddressLatLng (geocoder, address) {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK') {
                  resolve(results[0].geometry.location);
              } else {
                  reject(status);
              }    
          });    
        });
      },

      // clientData () {
      //   boticario().then(async (data) => {
      //     const $ = this;
      //     data = _.map(data.default, p => {
      //       if (!_.isNumber(p.audiencia)) console.log(p);
      //       return {
      //         geofence: {
      //           id: p.id_geojson,
      //           title: p.titulo_bairro,
      //           city: {
      //             id: p.id_cidade,
      //             title: p.titulo_cidade,
      //           },
      //         },
      //         timestamp: p.data,
      //         impressions: p.exibicoes,
      //         audience: p.audiencia,
      //         spent: p.realizado,
      //       }
      //     })
      //     console.log('Dados Boticario processados', data);
      //     // this.geofences.data = Object.assign(this.geofences.data, geofences);
      //     const key = this.dataKey.toString();
      //     this.$set(this.source, key, Object.assign({}, this.source.prototype));
      //     _.each(this.pipeline, (p, k) => {
      //       this[p.set](key, data, this.scope, this.budget, this.duration);
      //     });
      //   });
      // },

      getClientPois () {
        let data = [];
        _.each(this.data.pois.clients.source, (client, c) => {
          if (c==this.scope) {
            this.data.pois.point = true;
            this.data.pois.clients.set = c;
            data = _.concat(data, _.values(_.reduce(client, (process, p, i) => {
              if (!_.has(p, 'timestamp')||(moment(p.dia).isSameOrAfter(this.period[0])&&moment(p.dia).isSameOrBefore(this.period[1]))) {
                if (!_.has(p, 'id')||!_.has(process, p.id)) {
                  process[_.has(p, 'id') ? p.id : i] = {
                    id: _.has(p, 'id') ? p.id : i, 
                    title: p.poi,
                    position: {
                      lat: p.lat,
                      lng: p.log
                    },
                    type: 'POI',
                    radius: p.raio,
                    audience: p.audiencia,
                    impressions: p.exibicoes,
                    spent: p.realizado,
                    cpm: (p.realizado / p.audiencia) * 1000,
                    airtime: (p.exibicoes * 8) / 60 / 60,
                    city: { 
                      id: _.has(p, 'id_cidade') ? p.id_cidade : 3, 
                      title: _.has(p, 'nm_cidade') ? p.nm_cidade : 'Rio de Janeiro', 
                    },
                    ...(_.has(p, 'icon') ? { icon: p.icon } : {})
                  };
                }else{
                  const audience = process[p.id].audience + p.audiencia;
                  const impressions = process[p.id].impressions + p.exibicoes;
                  const spent = process[p.id].spent + p.realizado;
                  process[p.id] = _.assign(process[p.id], {
                    audience,
                    impressions,
                    spent,
                    cpm: (spent / audience) * 1000,
                    airtime: (impressions * 8) / 60 / 60,
                  });
                }
              }
              return process;
            }, {})));
          } 
        })
        return data;
      },

      clientsPois () {
        diageoPois().then(async (data) => {
          this.fetchPois('74', data);
        });
        descomplica57Pois().then(async (data) => {
          this.fetchPois('57', data);
        });
        descomplica40Pois().then(async (data) => {
          this.fetchPois('40', data);
        });
        descomplica16Pois().then(async (data) => {
          this.fetchPois('16', data);
        });
        boticarioPois().then(async (data) => {
          this.fetchPois('257', data);
        });
        boticarioPois283().then(async (data) => {
          this.fetchPois('283', data);
        });
        boticarioPois438().then(async (data) => {
          this.fetchPois('438', data);
        });
        boticarioPois481().then(async (data) => {
          this.fetchPois('481', data);
        });
        boticarioPois513().then(async (data) => {
          this.fetchPois('513', data);
        });
        boticarioPois514().then(async (data) => {
          this.fetchPois('514', data);
        });
        boticarioPois540().then(async (data) => {
          this.fetchPois('540', data);
        });
        boticarioPois666().then(async (data) => {
          this.fetchPois('666', data);
        });
        // this.csvToJson ('/ooh-pois.tsv', (error, json) => {
        //   if (error) {
        //     console.error('Error:', error);
        //   } else {
        //     console.log('JSON:', json);
        //     const data = _.map(json, p => {
        //       return {
        //         lat: parseFloat(p.lat),
        //         log: parseFloat(p.lng),
        //         raio: 500,
        //         exibicoes: 1,
        //         audiencia: 1,
        //         realizado: 1,
        //       }
        //     });
        //     this.fetchPois(null, data);
        //   }
        // });
        // itauPersonalite().then(async (data) => {
        //   this.fetchPois(null, data);
        // });
      },
      async fetchPois (key, data) {
        console.log(`POIs ${key} carregados`);
        key = key==null ? this.scope : key;
        const $ = this;
        let pois = 'default' in data ? data.default : data;
        
        if (_.some(pois, p => (p.lat==null||p.log==null))) {
          const geocoder = new $.google.maps.Geocoder();
          if (_.some(pois, p => _.isString(p))) pois = _.map(pois, (p,i) => {
            return {
              "id": _.has(p, 'id') ? p.id : i,
              "poi": p,
              "lat": null,
              "log": null,
              "exibicoes": 1,
              "audiencia": 1,
              "realizado": 1,
              "raio": _.has(p, 'raio') ? p.raio : 1000,
              ...(_.has(p, 'icon') ? { 
                icon: _.isString(p.icon) ? {
                  "url": p.icon,
                  "size": { "width": 24, "height": 24, "f": "px", "b": "px" },
                  "anchor": { "x": 16, "y": 16 }
                } : p.icon }
                : {})
            }
          })
          for await (let p of pois) {
            if (p.lat==null||p.log==null) {
              try {
                await this.pause(250);
                const position = await this.getAddressLatLng(geocoder, p.poi);
                p.lat = position.lat(); 
                p.log = position.lng();
                console.log(p);
              } catch (err) {
                console.log(err, p.poi);
              }
            }
          } 
        }

        this.data.pois.clients.source[key] = pois;
        if (this.scope==key&&this.data.pois.clients.set!=key) {
          console.log('Set Pois:', this.scope, key, pois);
          this.data.pois.data = _.concat(this.data.pois.data, this.getClientPois());
          this.onLayerChange('pois');
        }
      },

      pause (delay) {
        return new Promise(resolve => setTimeout(resolve, delay));
      }
    },

    mounted () {
    }
  }
</script>
